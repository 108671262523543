import React, {useRef, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import { Container, Wrapper, Backdrop } from './styles';

export type TooltipProps = {
  title?: string;
  body?: string;
  position?: "top-start" | "top" | "top-end" | "left-start" | "left" | "left-end" | "right-start" | "right" | "right-end" | "bottom-start" | "bottom" | "bottom-end";
  component?: React.ReactElement;
  isPopOver?: boolean;
  children: React.ReactNode;
  zIndex?: number;
  zIndexBackdrop?: number;
}

export const Tooltip = ({ 
  children, 
  title, 
  body, 
  component, 
  position,
  isPopOver = false,
  zIndex = 2,
  zIndexBackdrop = 1,
}: TooltipProps) => {

  const [isShowing, setIsShowing] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [parentPosition, setParentPosition] = useState({});
  const parentRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentRef.current && tooltipRef.current) {
      const elementHeight = (tooltipRef.current.getBoundingClientRect().height);
      const elementWidth = (tooltipRef.current.getBoundingClientRect().width);
      
      const currentScrollTop = document.documentElement.scrollTop;
      const currentScrollLeft = document.documentElement.scrollLeft;

      switch(position) {
        case "top": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().top - elementHeight - 10 + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().left + (parentRef.current.getBoundingClientRect().width / 2) - (elementWidth / 2) + currentScrollLeft,
          });
          break;
        case "top-start": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().top - elementHeight - 10 + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().left - (elementWidth / 2) + currentScrollLeft,
          });
          break;
        case "top-end": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().top - elementHeight - 10 + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().left + (elementWidth) - 15 + currentScrollLeft,
          });
          break;
        case "bottom": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().bottom + 10 + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().left + (parentRef.current.getBoundingClientRect().width / 2) - (elementWidth / 2)  + currentScrollLeft,
          });
          break;
        case "bottom-start": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().bottom + 10 + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().left - (elementWidth / 2) + currentScrollLeft,
          });
          break;
        case "bottom-end": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().bottom + 10 + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().left + (elementWidth) - 15 + currentScrollLeft,
          });
          break;
        case "left": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().top + (parentRef.current.getBoundingClientRect().width / 2) - (elementHeight / 2) + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().left - elementWidth - 10 + currentScrollLeft,
          });
          break;
        case "left-start": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().top - (elementHeight / 2) + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().left - elementWidth - 10 + currentScrollLeft,
          });
          break;
        case "left-end": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().bottom - (elementHeight / 2) + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().left - elementWidth - 10 + currentScrollLeft,
          });
          break;
        case "right": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().top + (parentRef.current.getBoundingClientRect().width / 2) - (elementHeight / 2) - 10  + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().right + 10 + currentScrollLeft,
          });
          break;
        case "right-start": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().top - (elementHeight / 2) - 10 + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().right + 10 + currentScrollLeft,
          });
          break;
        case "right-end": 
          setParentPosition({
            top: parentRef.current.getBoundingClientRect().bottom - (elementHeight / 2) - 10 + currentScrollTop,
            left: parentRef.current.getBoundingClientRect().right + 10 + currentScrollLeft,
          });
          break;
        default:
          break;
      }
      
    }
  }, [isShowing, isActive])

  return <>
    {isShowing || isActive ? 
      ReactDOM.createPortal(
        <>
          <Container parentPosition={parentPosition} ref={tooltipRef} position={position} zIndex={zIndex}>
            {component ?
              component
            :
              <>
                <p className="title">{title}</p>
                <p>{body}</p>
              </>
            }
          </Container>
          {isActive && <Backdrop onClick={() => setIsActive(false)} zIndexBackdrop={zIndexBackdrop} />}
        </>,
        document.body
      )
    : null}
    <Wrapper 
      ref={parentRef}
      onMouseEnter={() => isPopOver ? null : setIsShowing(true)}
      onMouseLeave={() => isPopOver ? null : setIsShowing(false)}
      onClick={() => isPopOver && setIsActive(!isActive)}
    >
      {children}
    </Wrapper>
  </>
}
import styled, { css } from 'styled-components';

type ContainerProps = {
  size: number;
  isChecked: boolean;
  disabled: boolean;
  intermediate: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  user-select: none;
  cursor: pointer;
  padding: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};

  border: ${props => props.isChecked && !props.intermediate && !props.disabled ? 'none' : '2px solid #8C8B8B'};
  border-radius: 2px;
  background-color: ${props => props.isChecked && !props.intermediate && !props.disabled ? '#1B66B0' : 'transparent'};

  &:active:after {
    content: '';
    position: absolute;
    z-index: -1;

    width: ${props => `${props.size * 2}px`};
    height: ${props => `${props.size * 2}px`};
    border-radius: 50%;
    background-color: ${props => props.isChecked && !props.intermediate ? '#c8daec' : '#F2F4F4'};
  }

  ${props => props.disabled && css`
    opacity: 0.8;
    pointer-events: none;
    background-color: ${props.intermediate ? 'transparent' : '#E5E5E5'};
  `}

`;
import styled from 'styled-components';

type ContainerProps = {
    hasValue: boolean;
    type: "simple" | "complex";
}

export const Container = styled.div<ContainerProps>`
    min-height: 40px;
    border-radius: ${props => props.type === "complex" ? '5px' : '0px'};
    border: ${props => props.type === "complex" && (props.hasValue ? `2px solid #596778` : `1px solid #596778`)};
    border-bottom: ${props => props.type === "simple" && (props.hasValue ? `2px solid #596778` : `1px solid #596778`)};
    padding: 4px 8px;
    background-color: #fff;
    font-size: 14px;
    color: #596778;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 8px;
    }
`;

export const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 150px;
    overflow: auto;
    margin-top: -16px;
    margin-bottom: -16px;
    margin-left: -16px;
    margin-right: -16px;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Item = styled.div`
    cursor: pointer;

    p {
        margin: 0 16px;
    }

    &:hover {
        background-color: #F2F4F4;
    }
`;

type LabelProps = {
    color: string;
}

export const Label = styled.label<LabelProps>`
    font-size: 14px;
    color: ${props => props.color};
    margin-left: 8px;
`;

type TextProps = {
    color: string;
}

export const Text = styled.p<TextProps>`
    font-size: 12px;
    color: ${props => props.color};
    margin-left: 8px;
`;




import React, { useState } from 'react';
import PieMenu, { Slice, background, PieCenter  } from 'react-pie-menu';
import { ThemeProvider, css } from 'styled-components';

import { Icon } from '../IconExporter';
import * as S from "./styles";

const theme = {
  pieMenu: {
    container: css`
      // style color...
      background-color: #dedede;
    `,
    list: css`
      // set pie size...
    `,
    item: css`
      // rotate slice...
    `,
    center: css`
      background-color: #fff;
      // style color...
    `,
  },
  slice: {
    container: css`
      &:hover {
        ${background('#1b66b0')}
      }
      &:hover svg {
        fill: #fff;
      }
      cursor: pointer;
      display: flex;
      justify-content: center;
      // style color...
    `,
    contentContainer: css`
      // center content...
    `,
    content: css`
      // rotate content...
      display: flex;
      justify-content: center;
    `,
  }
}

type MenuItem = {
  iconName: string;
  label: string;
  path: string;
}

type CustomCenterProps = {
  item: MenuItem;
  centerRender?: (selected) => JSX.Element;
  placeholder?: string;
};

export type CircularMenuProps = {
  radius: number;
  centerRadius: number;
  isOpen?: boolean;
  iconSize?: number;
  iconColor?: string;
  items: MenuItem[];
  onSelectCallback: (item) => void;
  centerRender?: (selected) => JSX.Element;
  placeholder?: string;
}

const CustomCenter = ({ item, centerRender: CenterRender, placeholder }: CustomCenterProps) => {
  return <PieCenter style={S.PieCenter}>
    {CenterRender ? (
      CenterRender(item)
    ) : (
      <S.PieCenterText>{item?.label ?? placeholder}</S.PieCenterText>
    )}
  </PieCenter>
}

export const CircularMenu = ({
  radius,
  centerRadius,
  isOpen = true,
  items,
  onSelectCallback,
  iconSize = 40,
  iconColor="#1b66b0",
  centerRender,
  placeholder,
}: CircularMenuProps) => {

  const [currentItem, setCurrentItem] = useState<MenuItem>(null);

  return <>
    {isOpen && 
      <ThemeProvider theme={theme}>
        <PieMenu
          polar
          radius={`${radius}px`}
          centerRadius={`${centerRadius}px`}
          Center={() => <CustomCenter item={currentItem} placeholder={placeholder} centerRender={centerRender} />}
        >
          {items && items.map(item => 
            <Slice
              backgroundColor="transparent"
              key={item.path}
              onMouseOver={() => setCurrentItem(item)}
              onMouseUp={() => setCurrentItem(null)}
              onSelect={() => onSelectCallback(item)}
            >
              <Icon name={item.iconName} size={iconSize} color={iconColor} />
            </Slice>
          )}
        </PieMenu>
      </ThemeProvider>
    }
  </>
}
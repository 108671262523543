import React, { useState } from 'react';

import { Container, ItemContainer } from './styles';

type TabsProps = {
  items: string[];
  type: 'default' | 'alternative' | 'slim';
  handleClickCallback?: (value: string) => void;
  initialValue?: string;
}

export const Tabs = ({ items, type = 'default', handleClickCallback, initialValue}: TabsProps) => {

  const [selectedItem, setSelectedItem] = useState(initialValue ?? '');

  const handleClick = (item: string) => {
    setSelectedItem(item);
    if (handleClickCallback) handleClickCallback(item);
  }

  return <Container type={type}>
    {items && items.map(item => 
      <ItemContainer 
        key={item} 
        isSelected={selectedItem === item}
        type={type}  
        onClick={() => handleClick(item)}
      >
        <p>{item}</p>
      </ItemContainer>
    )}
  </Container>
}
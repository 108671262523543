import React, { useState } from 'react';

import { Icon } from '../IconExporter';
import { Container } from './styles';

type CardProps = {
  children: React.ReactNode;
  hasElevation?: boolean;
  headerColor?: string;
  textColor?: string;
  bold?: boolean;
  title?: string;
  titleIcon?: string;
  headerCtaIcon?: string;
  handleCtaClick?: () => void;
  className?: string;
  hasAccordionBehavior?: boolean;
}

export const Card = ({
  children, 
  hasElevation = true, 
  headerColor = '#5A8DEE',
  textColor = "#FFF",
  bold = true,
  title,
  titleIcon,
  headerCtaIcon,
  handleCtaClick,
  className,
  hasAccordionBehavior = false,
}: CardProps) => {

  const [isOpen, setIsOpen] = useState(true);

  return <Container  
    className={className}
    hasElevation={hasElevation} 
    headerColor={headerColor} 
    textColor={textColor}
    bold={bold}
    isOpen={isOpen}
  >
    {title && 
      <header>
        <div className="startBlock">
          <Icon name={titleIcon ? titleIcon : ''} />
          <p>{title}</p>
        </div>
        
        {headerCtaIcon && !hasAccordionBehavior && 
          <div className="endBlock" onClick={handleCtaClick}>
            <Icon name={headerCtaIcon} />
          </div>
        }
        {hasAccordionBehavior && 
          <div className="endBlock" onClick={() => setIsOpen(!isOpen)}>
            <Icon size={12} name={isOpen ? "arrowup" : "arrowdown"} />
          </div>
        }
      </header>
    }
    <section>
      {isOpen && children}
    </section>
  </Container>
}
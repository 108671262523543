import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  position: relative;
`;

type Position = {
  top?: number;
  left?: number;
  width?: number;
  height?: number;
}

type PopOverContainerProps = {
  parentPosition: Position;
  zIndex: number;
}

export const PopOverContainer = styled.div<PopOverContainerProps>`
  position: absolute; 
  top: ${props => props.parentPosition.top ?? 0};
  left: ${props => props.parentPosition.left ?? 0};
  padding: 16px;
  z-index: ${props => props.zIndex};
  margin-top: 2px;

  width: ${props => props.parentPosition.width ? `${props.parentPosition.width}px` : 'auto'};
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px #00000033;
`;

export const Button = styled.button``;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 22px;
  }
`;
import React from 'react';

import { Icon } from '../IconExporter';
import { Container } from './styles';

export type AlertProps = {
  text: string;
  type?: "normal" | "secondary" | "success" | "error" | "alert" | "info" | "alternative";
}

export const Alert: React.FC<AlertProps> = ({text, type = "normal"}) => {

  const iconByType = {
    normal: 'estrelavazada',
    secondary: 'link',
    success: 'curtir',
    error: 'notificacao1',
    alert: 'atencao',
    info: 'ticket',
    alternative: 'estrelavazada',
  }
  
  return <Container type={type}>
    <Icon name={iconByType[type]}size={18} />
    <p>{text}</p>
    <Icon name="fechar2" size={16} className="close"/>
  </Container>
}
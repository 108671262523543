import React from 'react';

import { Icon } from '../IconExporter';
import { CustomButton } from './styles';

export type ButtonProps = {
  label?: string;
  variant?: "primary" | "outline" | "round";
  color?: string;
  icon?: string;
  iconColor?: string;
  isDisabled?: boolean;
  handleClick?: () => void;
}

export const Button = ({label, variant = "primary", color = "#5A8DEE", icon, iconColor = '#fff', isDisabled = false, handleClick}: ButtonProps) => {
  return <CustomButton variant={variant} color={color} isDisabled={isDisabled} onClick={handleClick}>
    {label && label}
    {icon && <Icon name={icon} size={24} color={iconColor} />}
  </CustomButton>;
}
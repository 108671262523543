import styled from 'styled-components'

export const BtMore = styled.span`
  content: '';
  left: 10px;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #3f89c1;
  clear: both;
  transform: rotate(180deg);
  cursor: pointer;
`
export const BtLess = styled.span`
  content: '';
  left: 0px;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #3f89c1;
  clear: both;
  cursor: pointer;
`
export const Label = styled.label`
  margin-right: 5px;
  width: 20px;
  text-align: center;
`
export const DivContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
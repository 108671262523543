import React, {useState, useEffect, useRef} from 'react';

import { Overlay } from '../Overlay';
import { Container, PopOverContainer,ItemContainer, Button } from './styles';

export type ItemType = {
  label: string;
  icon?: string;
}

export type DropdownProps = {
  children: React.ReactNode;
  dropContent: React.ReactNode;
  fitToParentWidth?: boolean;
  zIndex?: number;
  className?: string;
  onlyOutClose?: boolean;
}

export const Dropdown = ({
  children, 
  dropContent,
  fitToParentWidth = true, 
  zIndex = 2,
  className,
  onlyOutClose,
}: DropdownProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const [parentPosition, setParentPosition] = useState({});
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentRef.current) {
      setParentPosition({
        top: parentRef.current.getBoundingClientRect().bottom,
        left: parentRef.current.getBoundingClientRect().left,
        width: fitToParentWidth ? parentRef.current.getBoundingClientRect().width : null,
      });
    }
  }, [isOpen, fitToParentWidth])
  
  return <>
    {isOpen && <Overlay handleClick={() => setIsOpen(false)} bgColor="transparent" />}
    <Container className={className} onClick={() => setIsOpen(onlyOutClose ? true : !isOpen)}ref={parentRef}>
      {children}

      {isOpen && dropContent && 
        <PopOverContainer parentPosition={parentPosition} zIndex={zIndex}>
          {dropContent}         
        </PopOverContainer>       
      }
    </Container>
  </>
};

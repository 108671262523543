import React, { useState, useEffect } from 'react';
import { Icon } from '../IconExporter';
import { BiDotsHorizontalRounded } from 'react-icons/bi';

import {Select} from "../Select";
import {Tooltip} from '../Tooltip';
import { Container, Block, InputContainer } from './styles';

export type PaginationProps = {
  initialPage?: number;
  totalPages: number;
  siblingsCount: number;
  amountVariations: number[];
  handleChangeAmount: (amount: string | number) => void;
  handlePageChange: (currentPage: number) => void;
}

export const Pagination = ({initialPage = 1, totalPages = 1, siblingsCount, handleChangeAmount, handlePageChange, amountVariations}: PaginationProps) => {

  const [currentPage, setCurrentPage] = useState(initialPage > totalPages ? 1 : initialPage);
  const [inputValue, setInputValue] = useState('');

  const selectOptions = amountVariations?.map(amount => {
      const option = {
        label: `${amount}/page`,
        value: +amount,
      }
      return option;
  })

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  }

  const handleForward = () => {
    setCurrentPage(prevState => prevState + 1 <= totalPages ? prevState + 1 : totalPages);
  }

  const handleBackward = () => {
    setCurrentPage(prevState => prevState - 1 >= 1 ? prevState - 1 : 1);
  }

  const setManuallyCurrentPage = () => {
    const value = +inputValue.replace(/\D/gi, '');
    if (value && value <= totalPages && value > 0) { 
      setCurrentPage(value);
      setInputValue('');
    } else {
      setInputValue('');
    }
  }

  useEffect(() => {
    if (handlePageChange) handlePageChange(currentPage);
  }, [currentPage, handlePageChange])

  return <Container>
      <Block onClick={() => setCurrentPage(1)} isActive={false}>
        <Icon name="paginacaoprimeira" size={14} color="#565656"/>
      </Block>
      <Block onClick={handleBackward} isActive={false}>
        <Icon name="setaesquerda" size={14} color="#565656"/>
      </Block>
      {currentPage - siblingsCount > 2 &&
      <Tooltip
        isPopOver
        position="top"
        component={
          <InputContainer>
            <p>Ir para:</p>
            <input 
              value={inputValue} 
              onChange={(e) => setInputValue(e.target.value)} 
              onBlur={setManuallyCurrentPage} 
            />
          </InputContainer>
        }
      >
        <Block isActive={false}>
          <BiDotsHorizontalRounded size={14}/>
        </Block>
      </Tooltip> 
      }
      {[...new Array(totalPages)]?.map((_, index) => {
        if (((index + 1 >= currentPage && index + 1 <= currentPage + siblingsCount) ||
        (index + 1 >= currentPage - siblingsCount && index + 1 < currentPage)) && index + 1 !== totalPages
        ) {
          return (
            <Block onClick={() => handlePageClick(index + 1)} isActive={currentPage === index + 1} >
             {index + 1}
            </Block>
          )
        }
        return null;
      })}
      {currentPage + siblingsCount < totalPages - 1 && 
        <Tooltip
          isPopOver
          position="top"
          component={
            <InputContainer>
              <p>Ir para:</p>
              <input 
                value={inputValue} 
                onChange={(e) => setInputValue(e.target.value)} 
                onBlur={setManuallyCurrentPage} 
              />
            </InputContainer>
          }
        >
          <Block isActive={false}><BiDotsHorizontalRounded size={14}/></Block>
        </Tooltip>
      }
      <Block onClick={() => setCurrentPage(totalPages)} isActive={currentPage === totalPages}>
        {totalPages}
      </Block>
      <Block onClick={handleForward} isActive={false}>
        <Icon name="setadireita" size={14} color="#565656"/>
      </Block>
      <Block onClick={() => setCurrentPage(totalPages)} isActive={false}>
      <Icon name="paginacaoultima" size={14} color="#565656"/>
      </Block>
      <Block isActive={false}>
        <Select 
          data={selectOptions} 
          handleChangeCallback ={(value) => {
            if (handleChangeAmount) {
              handleChangeAmount(value?.value)
            } 
          }}
        />
      </Block>
    </Container>
}
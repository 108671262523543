import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 5px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #5A8DEE;
    padding: 8px 16px;

    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    & > div {
      display: flex;
      align-items: center;
      gap: 18px;

      p {
        font-size: 15px;
        color: #fff;
        text-transform: uppercase;
      }

      svg {
        fill: #fff;
      }
    }
  }

  section {
    padding: 16px;
    background-color: #fff;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;

    iframe {
      width: 100%;
    }
  }
`;
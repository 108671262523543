import styled, { css } from 'styled-components';

type ContainerProps = {
  
}


export const Container = styled.div<ContainerProps>`

`;

export const PieCenter = {
  display: 'flex',
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
};

export const PieCenterText = styled.p`
  color: "#596778";
  font-weight: "bold";
  font-size: "15px";
`;
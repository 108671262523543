import React, { useState, useEffect } from 'react';

import { Dropdown } from '../Dropdown';
import { Icon } from '../IconExporter';
import { Container, ItemsContainer, Item, Label, Text } from './styles';

type Item = {
  label: string;
  value: number | string;
}

export type SelectProps = {
  type?: 'simple' | 'complex';
  data: Item[];
  handleChangeCallback: (value: Item) => void;
  initialValue?: Item;
  label?: string;
  warn?: string;
  className?: string;
  color?: string;
  placeholder?: string;
}

export const Select = ({type = 'simple', data, handleChangeCallback, initialValue, label, warn, className, color = "#000", placeholder }: SelectProps) => {
  const [inputValue, setInputValue] = useState(initialValue);

  const handleChange = (item: Item) => {
    setInputValue(item);
    handleChangeCallback && handleChangeCallback(item);
  };

  const getList = () => {
    if (!data) return null;
    return <ItemsContainer>
      {data.map(item => 
        <Item key={item.value} onClick={() => handleChange(item)}>
          <p>{item.label ?? item.value}</p>
        </Item>
      )}
    </ItemsContainer>
  }

  if (!data) return null;

  return <Dropdown className={className} fitToParentWidth={false} dropContent={getList()}>
    {label && <Label color={color}>{label}</Label>}
    <Container hasValue={inputValue?.label !== 'Selecione' && inputValue?.value !== ''} type={type}>
      <p>{inputValue?.label ?? placeholder}</p>
      <Icon name="dropdown" color="#8C8B8B" size={12} />
    </Container>
    {warn && <Text color={color}>{warn}</Text>}
  </Dropdown>
}
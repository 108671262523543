import styled from 'styled-components';

type ContainerProps = {
  color: string;
  lastChildHeight: number;
}

export const Container = styled.div<ContainerProps>`

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;

  & > p {
    background-color: ${props => props.color};
    border-radius: 5px;
    color: #fff;
    align-self: flex-start;
    position: relative;
    z-index: 2;
    margin-bottom: 16px;
    padding: 8px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 12px;
    width: 1px;
    height: ${props => props.lastChildHeight && `calc(100% - ${props.lastChildHeight}px)`};
    border-right: 2px dashed #E0E0E0;
    z-index: 2;
  }

  .card {
    width: 440px;
    height: auto;
    margin-left: 30px;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0px 2px 4px #00000033;
    position: relative;
    transform-style: preserve-3d;
    padding: 16px;
  }

  
  .card:before {
    content: '';
    width: 100%;
    height: 1px;
    border-top: 2px dashed #E0E0E0;
    top: 50%;
    left: -15px;
    transform: translateZ(-1px);
    position: absolute;
  }

  .card:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;
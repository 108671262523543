import styled from 'styled-components';

export const Container = styled.div `
    width: 100%;
    display: flex;
    align-items: center;

    > label {
        padding-left: 15px;
    }

    b {
        font-weight: bold;
    }
`;

type SliderProps = {
    backgroundChecked: boolean;
}

export const Slider = styled.span<SliderProps> `
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 5px;
    right: 0;
    bottom: 0;
    background-color: #e5e5e5;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: -7px;
        bottom: -3px;
        background-color: ${ props => props.backgroundChecked ? '#1b66b0' : '#bababa'} ;
        transition: .4s;

        &.checked {
            background: yellow;
        }
    }
`;

export const SwitchContainer = styled.label `
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    > input { 
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: #b1cbe4;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
        }

        &:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
    }

    .slider.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }
`;

import styled, { css } from 'styled-components';

type Position = {
  top?: number;
  left?: number;
  width?: number;
  height?: number;
}

type ContainerProps = {
  parentPosition: Position;
  zIndex: number;
  position?: "top-start" | "top" | "top-end" | "left-start" | "left" | "left-end" | "right-start" | "right" | "right-end" | "bottom-start" | "bottom" | "bottom-end";
}

export const Container = styled.div<ContainerProps>`
  background-color: #fff;
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  z-index: ${props => props.zIndex};
  top: ${({ parentPosition }) => parentPosition.top && parentPosition.top}px;
  left: ${({ parentPosition }) => parentPosition.left && parentPosition.left}px;

  p {
    color: #596778;
    padding: 8px;
  }

  .title {
    font-weight: bold;
    padding: 4px 8px;
    border-bottom: 1px solid #EDEDED;
  }

  &:after {
    content: '';
    width: 0; 
    height: 0; 
    border-left: ${props => props.position?.includes('left') ? '10px solid #fff' : (props.position?.includes('right') ? 'none' : '10px solid transparent')}; 
    border-right: ${props => props.position?.includes('right') ? '10px solid #fff' : (props.position?.includes('left') ? 'none' : '10px solid transparent')}; 
    border-top: ${props => props.position?.includes('top') ? '10px solid #fff' : (props.position?.includes('bottom') ? 'none' : '10px solid transparent')}; 
    border-bottom: ${props => props.position?.includes('bottom') ? '10px solid #fff' : (props.position?.includes('top') ? 'none' : '10px solid transparent')};  
    position: absolute;
    left: ${props => ['bottom', 'top'].some(pos => props.position?.includes(pos)) ? '50%': (props.position?.includes('left') ? 'calc(100% + 5px)' : 'unset')};
    bottom: ${props => props.position?.includes('top') ? '0': (props.position?.includes('left') ? '50%' : 'unset')};
    top: ${props => props.position?.includes('bottom') ? '-10px': (props.position?.includes('right') ? '50%' : 'unset')};
    right: ${props => ['bottom', 'top'].some(pos => props.position?.includes(pos)) ? 'unset': (props.position?.includes('right') ? 'calc(100% - 5px)' : 'unset')};
    transform: translateX(-50%);
    margin-bottom: -10px;
    ${props => {
     switch(props.position) {
        case 'top':
        case 'top-start':
        case 'top-end':
          return css`
            filter: drop-shadow(0px 1px 1px rgba(0,0,0,0.2));
          `;
        case 'bottom':
        case 'bottom-start':
        case 'bottom-end':
          return css`
            filter: drop-shadow(0px -1px 1px rgba(0,0,0,0.2));
          `;
        case 'left':
        case 'left-start':
        case 'left-end':
          return css`
            filter: drop-shadow(1px 0px 1px rgba(0,0,0,0.2));
          `;
        case 'right':
        case 'right-start':
        case 'right-end':
          return css`
            filter: drop-shadow(-1px 0px 1px rgba(0,0,0,0.2));
          `;
        default:
          break;
      } 
    }} 
  }
`;

export const Wrapper = styled.span`
  display: inline-flex;
`;

type BackdropProps = {
  zIndexBackdrop: number;
}

export const Backdrop = styled.div<BackdropProps>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${props => props.zIndexBackdrop};
`;
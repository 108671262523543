import styled from 'styled-components';

type ContainerProps = {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  & > header {
    cursor: pointer;
  }

  & > section {
    transition: all 0.2s ease-out;
    transform-origin: top;
    transform: ${props => props.isOpen ? 'scaleY(1)' : 'scaleY(0)'};
    max-height: ${props => props.isOpen ? '10000px' : '0px'};
    overflow: hidden;
  }
`;
import React from 'react';

import { Container } from './styles';

type OverlayProps = {  
  bgColor?: string;
  zIndex?: number;
  handleClick?: () => void;
}

export const Overlay = ({bgColor = "rgba(0,0,0,0.6)", zIndex = 1, handleClick}: OverlayProps) => {
  return <Container bgColor={bgColor} zIndex={zIndex} onClick={handleClick && handleClick} />
}
import styled from "styled-components";

export const Container = styled.div`
  max-width: 230px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  input {
    border-bottom: 1px solid #596778;
  }

  section {
    max-height: 200px;
    overflow: auto;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      p {
        margin-left: 7px;
      }
    }
  }  

  footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 7px;
      background-color: transparent;
    }

    button.filter {
      color: #5A8DEE;
    }
  }  
`;

import styled from 'styled-components';

type ContainerProps = {
  type: "normal" | "secondary" | "success" | "error" | "alert" | "info" | "alternative"
  visible?: boolean;
}

const colorStyles = {
  normal: {
    bg: "#5A8DEE",
    itemsColor: "#fff",
    border: 'none',
  },
  secondary: {
    bg: "#475F7B",
    itemsColor: "#fff",
    border: 'none',
  },
  success: {
    bg: "#ADC86A",
    itemsColor: "#fff",
    border: 'none',
  },
  error: {
    bg: "#FF3333",
    itemsColor: "#fff",
    border: 'none',
  },
  alert: {
    bg: "#F6B000",
    itemsColor: "#fff",
    border: 'none',
  },
  info: {
    bg: "#BEDEEC",
    itemsColor: "#596778",
    border: 'none',
  },
  alternative: {
    bg: "#fff",
    itemsColor: "#5A8DEE",
    border: '1px solid #5A8DEE',
  },   
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  box-shadow: 0px 3px 6px rgba(0,0,0,0.3);
  padding: 16px;
  border-radius: 5px;
  background-color: ${props => colorStyles[props.type].bg};
  border: ${props => colorStyles[props.type].border};

  p {
    font-size: 15px;
    color: ${props => colorStyles[props.type].itemsColor};
  }

  svg {
    margin-right: 8px;
    fill: ${props => colorStyles[props.type].itemsColor};
  }

  .close {
    margin: 0;
    margin-left: auto;
  }
`;

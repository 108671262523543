import styled, {css} from 'styled-components';

type ContainerProps = {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'paragraph' | 'lead' | 'quote' | 'muted' | 'body' | 'comment' | 'header1' | 'header2' | 'subHeader1' | 'subHeader2' | 'formLabel' | 'formInput' | 'formHelper' | 'formButton' | 'tableDescription' | 'tableBody';
  color?: string; 
  weight?: 'normal' | 'bold';
}

export const Container = styled.div<ContainerProps>`
  font-weight: ${props => props.weight};
  font-size: ${props => {
    switch(props.variant) {
      case 'h1':
        return '28px';
      case 'h2':
        return '24px';
      case 'h3':
        return '18px';
      case 'h4':
        return '16px';
      case 'h5':
        return '14px';
      case 'h6':
        return '12px';
      case 'paragraph':
        return '15px';
      case 'lead':
        return '18px';
      case 'quote':
        return '15px';
      case 'muted':
        return '15px';
      case 'body':
        return '15px';
      case 'header1': 
        return '22px';
      case 'header2': 
        return '18px';
      case 'subHeader1': 
        return '15px';
      case 'subHeader2': 
        return '12px';
      case 'body': 
        return '15px';
      case 'comment': 
        return '9px';
      case 'formLabel':
        return '14px';
      case 'formInput':
        return '14px';
      case 'formHelper':
        return '12px';
      case 'formButton':
        return '15px';
      case 'tableDescription':
        return '22px';
      case 'tableBody':
        return '12px';
      default: 
        return;
    }
  }};

  ${props => props.variant === 'muted' && css`
    color: #BABABA;
    user-select: none;
  `}

  ${props => props.variant === 'quote' && css`
    & span {
      color: #8C8B8B;
    }
    & span:first-of-type {
      margin-left: 8px;
      margin-right: 4px;
    }
  `}

  color: ${props => props.color};
`;

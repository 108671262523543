import React from 'react';

import { Icon } from '../IconExporter';
import { Container } from './styles';

export type TagProps = {
  label: string;
  type?: "simple" | "advanced";
  handleClick?: () => void;
  handleDelete?: () => void;
}

export const Tag = ({label, type = "simple", handleClick, handleDelete}: TagProps) => {
  return <Container type={type} className={handleClick && "clickable"}>
    <p onClick={handleClick}>{label}</p>
    {type === "advanced" && <Icon name="fechar1" color="#1B66B0" handleClick={handleDelete} />}
  </Container>;
}
import styled from 'styled-components';

type ContainerProps = {
  bgColor: string;
  zIndex: number;
}

export const Container = styled.div<ContainerProps>`
  width: 200vw;
  height: 200vh;

  transform: translate(-50%, -50%);

  position: absolute;
  top: 0;
  left: 0;
  background-color: ${props => props.bgColor};
  z-index: ${props => props.zIndex};
`;
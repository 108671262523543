import styled, {css} from 'styled-components';

export const Container = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  border: 1px solid #BABABA;
  padding: 14px;
  background-color: #fff;
`;

type BlockProps = {
  isActive: boolean;
}

export const Block = styled.div<BlockProps>`
  padding: 8px 16px;
  color: #565656;
  border: 1px solid #bababa;
  cursor: pointer;
  transition: all 0.2s;
  margin-right: 7px;

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  svg {
    fill: #565656;
  }

  select {
    border: none;
    color: #565656;
  }

  &:hover {
    border-color: #1B66B0;
    color: #1B66B0;

    svg {
      fill: #1B66B0;
    }
  }

  ${props => props.isActive && css`
    border-color: #1B66B0;
    color: #1B66B0;

    svg {
      fill: #1B66B0;
    }
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #596778;
  padding: 8px;

  p {
    padding: 0;
    margin-right: 8px;
  }

  input {
    color: #596778;
    text-align: center;
    max-width: 40px;
    border-bottom: 1px solid #596778;
  }
`;
import React, { useState, useEffect, ChangeEvent } from "react";
import { Popover } from "@material-ui/core";
import { Pagination } from "../Pagination";
import { FilterContainer } from "../FilterContainer";

import produce from "immer";
import { MdFilterList } from "react-icons/md";

import { Container } from "./styles";


type Column = {
  field: string;
  title: string;
}

type Row = {
  [key: string]: string | number;
}

type Search = {
  [column: string]: string[];
}

export type DatatableProps = {
  rawData: Row[];
  columns: Column[];
  itemsPerPage?: number[];
  selectedItems?: any[];
  selectableRows?: boolean;
  selectableRowsAll?: boolean;
  page?: number;
  pagination?: boolean;
  handleChangePage?: (page: number) => void;
  onSelectionChange: (list: number[]) => void;
}

export const Datatable = ({ 
  rawData = [],
  columns = [],
  selectableRows = false,
  selectableRowsAll = true,
  selectedItems = [],
  pagination = true,
  page=1,
  itemsPerPage = [10, 20, 30],
  handleChangePage,
  onSelectionChange,
}: DatatableProps) => {
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortingDirection, setSortingDirection] = useState("");
  const [isSortingBy, setIsSortingBy] = useState("");
  const [anchorEl, setAnchorEl] = useState<any>("");
  const [selectedRows, setSelectedRows] = useState(selectedItems);
  const [checkAll, setCheckAll] = useState(false);

  const [amountPerPage, setAmountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(page);

  const [searchValues, setsearchValues] = useState<Search>({});
  const [filteringColumn, setFilteringColumn] = useState("");

  useEffect(() => {
    setData(rawData);
  }, [rawData]);

  useEffect(() => {
    if (page !== currentPage) setCurrentPage(page);
  }, [page]);

  // Clean selected and dispatch onSelectionChange
  useEffect(() => {
    setSelectedRows([]);
    setCheckAll(false);
    onSelectionChange && onSelectionChange([]);
  }, [currentPage]);

  useEffect(() => {
    if (!searchValues) {
      return setData(rawData);
    }

    const newFilteredData = rawData.filter(row => {
      let returnRow = true;
      for (const key in searchValues) {
        if (searchValues[key]?.length > 0) {
          const filteredColumn = searchValues[key].some(searchValue => {
            return row[key].toString().toLowerCase() === (searchValue.toLocaleLowerCase());
          });

          if (!filteredColumn) returnRow = filteredColumn;
        }
      }
      return returnRow;
    });

    setFilteredData(newFilteredData);
    setData(newFilteredData);
    handleCloseFilter();
  }, [searchValues]);

  const compare = (baseState, key, currentDirection) =>
    produce(baseState, draft => {
      draft.sort((a, b) => {
        let comparisonValue = 0;
        let firstValue = a[key];
        let secondValue = b[key];

        if (currentDirection === "down")
          [firstValue, secondValue] = [secondValue, firstValue];

        if (typeof firstValue === "string") {
          if (firstValue > secondValue) {
            comparisonValue = 1;
            return 1;
          }
          if (firstValue < secondValue) {
            comparisonValue = -1;
            return -1;
          }
          if (firstValue === secondValue) {
            comparisonValue = 0;
            return 0;
          }
        }

        if (typeof firstValue === "number")
          comparisonValue = firstValue - secondValue;
        return comparisonValue;
      });
    });

  const handleSort = column => {
    let currentDirection = sortingDirection;

    if (isSortingBy !== column) {
      setIsSortingBy(column);
      setSortingDirection("");
      setData(filteredData);
      currentDirection = "";
    }

    if (currentDirection === "") setSortingDirection("down");
    if (currentDirection === "down") setSortingDirection("up");
    if (currentDirection === "up") setSortingDirection("");

    if (currentDirection === "up") {
      setData(filteredData);
    } else {
      const sortedData = compare(filteredData, column, currentDirection);
      setData(sortedData);
    }
  };

  const values = data && data.length > 0 && data
    .slice(
      currentPage * amountPerPage - amountPerPage,
      currentPage * amountPerPage,
    );

  const handleOpenFilter = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleChangePageLocal = (value: number) => {
    handleChangePage && handleChangePage(value);
    setCurrentPage(value)
  };

  const handleSelectRow = id => {
    let newSelectedRows;
    if (selectedRows.includes(id)) {
      newSelectedRows = selectedRows.filter(rowId => rowId !== id);
    } else {
      newSelectedRows = [...selectedRows, id];
    }
    setSelectedRows(newSelectedRows);

    onSelectionChange && onSelectionChange(newSelectedRows);
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const newSelectedRows = !event.target.checked ? [] : values.map(item => item.id);
    setSelectedRows(newSelectedRows);
    setCheckAll(!checkAll);
    onSelectionChange && onSelectionChange(newSelectedRows);
  }

  return (
    <Container>
      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              {selectableRows && (
                <th>
                  {selectableRowsAll && (
                    <input type="checkbox" checked={checkAll} onChange={handleSelectAll}/>
                  )}
                </th>
              )}
              {(columns || []).map(column => (
                <th key={column.field} title={column.title}>
                  <div className="headerCell">
                    <div
                      role="button"
                      onClick={() => handleSort(column.field)}
                      title={column.title}
                    >
                      {column.title}
                    </div>
                    <MdFilterList
                      style={{ flexShrink: 0, color: searchValues[column.field]?.length > 0 ? "#5A8DEE" : "" }}
                      onClick={e => {
                        setFilteringColumn(column.field);
                        handleOpenFilter(e);
                      }}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
                (pagination ? values : data)
                .map(row => {
                  return (
                    <tr className={selectedRows.includes(row.id) && "selected"}>
                      {selectableRows && (
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(row.id)}
                            onChange={() => handleSelectRow(row.id)}
                          />
                        </td>
                      )}
                      {columns.map(column => (
                        <td
                          key={`${row[column.field]}-${column.field}`}
                          title={row[column.field]}
                        >
                          {row[column.field]}
                        </td>
                      ))}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      {data && pagination && data.length > 0 && (
        <Pagination
          initialPage={page}
          totalPages={Math.ceil(data.length / amountPerPage)}
          siblingsCount={1}
          amountVariations={itemsPerPage}
          handleChangeAmount={value => {
            setAmountPerPage(+value.toString().replace(/\D/gi, "") ?? itemsPerPage[0]);
          }}
          handlePageChange={handleChangePageLocal}
        />
      )}
      <Popover
        id="popover-filter"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FilterContainer
          rawData={rawData}
          column={filteringColumn}
          values={searchValues[filteringColumn]}
          handleChangeSearch={(value: Search) => {
            setsearchValues({ ...searchValues, ...value });
          }}
        />
      </Popover>
    </Container>
  );
}
import styled from 'styled-components';

type ContainerProps = {
  size: number;
  isChecked: boolean;
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  user-select: none;
  cursor: pointer;

  background-color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};

  border: ${props => props.isChecked ? '2px solid #1B66B0' : '2px solid #8C8B8B'};
  border-radius: 50%;
  background-color: transparent;

  &:active:after {
    content: '';
    position: absolute;
    z-index: -1;

    width: ${props => `${props.size * 2}px`};
    height: ${props => `${props.size * 2}px`};
    border-radius: 50%;
    background-color: ${props => props.isChecked ? '#F2F4F4' : '#c8daec'};
  }

  & .dot { 
    width: ${props => `${props.size / 2}px`};
    height: ${props => `${props.size / 2}px`};
    background-color: #1B66B0;
    border-radius: 50%;
  }

`;
import styled from 'styled-components';

type ContainerProps = {
  modelType: "simple" | "advanced";
}

export const Container = styled.div<ContainerProps>`
  .MuiInput-underline:before {
    border: ${props => props.modelType === "advanced" && 'none'};
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: ${props => props.modelType === "advanced" && 'none'};
  }
  
  .container {
    border: 1px solid #596778;
    border-radius: 5px;
    padding: 5px;
  }
  
`;
import styled from 'styled-components';

export const Container = styled.div`
  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label, .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent;
  }

  .MuiTypography-body1 {
    font-family: 'Cairo';
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
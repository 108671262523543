import React from 'react';

import { Container } from './styles';

type TypographyProps = {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'paragraph' | 'lead' | 'quote' | 'muted' | 'body' | 'comment' | 'header1' | 'header2' | 'subHeader1' | 'subHeader2' | 'formLabel' | 'formInput' | 'formHelper' | 'formButton' | 'tableDescription' | 'tableBody'; 
  color?: string; 
  weight?: 'normal' | 'bold';
  label: string;
  quoteFrom?: string;
}

export const Typography: React.FC<TypographyProps> = ({
  variant = 'paragraph', 
  color, 
  label, 
  weight = 'normal',
  quoteFrom,
}) => {
  return <Container variant={variant} color={color} weight={weight}>
    <p>{label}</p>
    {variant === 'quote' && quoteFrom && 
      <>
        <span>-</span><span>{quoteFrom}</span>
      </>
    }
  </Container>
}
import styled, { css } from 'styled-components';
import { lighten } from 'polished';

type CustomButtonProps = {
  variant: "primary" | "outline" | "round";
  color: string;
  isDisabled: boolean;
}

export const CustomButton = styled.button<CustomButtonProps>`
  min-width: 109px;
  height: 38px;
  border-radius: 5px;
  background-color: ${props => props.color};
  transition: all 0.2s;

  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.variant === 'outline' && 
    css`
      background-color: #fff;
      color: ${(props: CustomButtonProps)  => props.color};
      border: 1px solid ${props => props.color};
    `
  } 

  ${props => props.variant === 'round' && 
    css`
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    `
  }

  &:hover, &:active  {
    background-color: ${props => lighten(0.1, props.color)};
    color: #fff;
  } 

  ${props => props.isDisabled && 
    css`
      opacity: 0.5;
      pointer-events: none;
    `
  }
`;

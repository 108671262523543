import React, { useState, useEffect } from "react";

import { Container } from "./styles";

type Row = {
  [key: string]: string | number;
}

type FilterItem = {
  value: string;
  checked: boolean;
}

export type FilterContainerProps = {
  rawData: Row[];
  column: string;
  values?: string[];
  handleChangeSearch?: Function;
}

export const FilterContainer = ({ rawData, column, values, handleChangeSearch }: FilterContainerProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [filterValues, setFilterValues] = useState<FilterItem[]>([]);
  const [checkAll, setCheckAll] = useState(true);

  useEffect(() => {
    const uniqueValues = [...new Set<string>(rawData.map(item => item[column].toString()))];
    const newFilterValues = uniqueValues.map((uniqueValue) => {
      return {
        value: uniqueValue,
        checked: true,
      }
    });

    if (!searchValue) {
      return setFilterValues(newFilterValues);
    }

    const filteredData = newFilterValues.filter(filter =>
      filter.value.toLowerCase().includes(searchValue.toLocaleLowerCase()),
    );

    setCheckAll(true);
    setFilterValues(filteredData);
  }, [rawData, searchValue]);

  useEffect(() => {
    if (values?.length > 0) {
      const uniqueValues = [...new Set<string>(rawData.map(item => item[column].toString()))];
      const filteredData = uniqueValues.map(uniqueValue => {
        return {
          value: uniqueValue,
          checked: values.includes(uniqueValue),
        };
      });
      setFilterValues(filteredData);
      setCheckAll(filteredData.every(item => item.checked));
    }
  }, [values]);

  const handleChangeChecked = (index?: number) => {
    if (index !== undefined) {
      const newFilterValues = filterValues.map((item, idx) => index !== idx ? item : { ...item, checked: !item.checked });
      setCheckAll(newFilterValues.every(item => item.checked));
      return setFilterValues(newFilterValues);
    }

    setFilterValues(filterValues.map((item => ({ ...item, checked: !checkAll }))));
    setCheckAll(!checkAll);
  }

  return (
    <Container>
      <input
        type="text"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
      />
      <section>
        {
          filterValues &&
          <div key="selectAll">
            <input
              type="checkbox"
              size={15}
              checked={checkAll}
              onChange={() => {
                handleChangeChecked();
              }}
            />
            <p>Selecionar todos</p>
          </div>
        }
        {
          filterValues &&
          filterValues.map((filterValue, index) => (
            <div key={filterValue.value}>
              <input
                type="checkbox"
                size={15}
                checked={filterValue.checked}
                onChange={() => {
                  handleChangeChecked(index);
                }}
              />
              <p>{filterValue.value}</p>
            </div>
          ))
        }
      </section>
      <footer>
        <button
          type="button"
          className="filter"
          onClick={() => {
            const filteredValues = filterValues.filter(item => item.checked);
            handleChangeSearch({ [column]: filteredValues.map(filteredValue => filteredValue.value) });
          }}>
          Filtrar
        </button>
        <button
          type="button"
          onClick={() => {
            setSearchValue("");
            handleChangeSearch({ [column]: [] })
          }}
        >
          Limpar
        </button>
      </footer>
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 5px;
  box-shadow: 0px 3px 6px #0000001F;
  background-color: #fff;
  display: inline-flex;
  align-items: center;

  svg {
    width: 15px;
    height: 15px;
    fill: #45924F;
    margin: 10px;
    cursor: pointer;
  }

  div.itemContainer {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 0 10px;

    & p {
      font-size: 12px;
      color: #596778;
    }

    & p:hover {
      font-weight: 700;
    }

    &.selected {
      border-radius: 5px;
      background-color: #5A8DEE;

      & p {
        color: #fff;
      }

      & p:hover {
        font-weight: normal;
      }
    }
  }
`;

export const Separator = styled.div`
  width: 1px;
  margin: 10px 0;
  background-color: #E5E5E5;
  align-self: stretch;
`;

import React, { useState, forwardRef } from 'react';
import { Icon } from '../IconExporter';
import { Container, InputContainer } from './styles';
import { formatCpf, formatCnpj, formatMoney, formatCep, formatMoneyWithoutCurrency } from './masks';

type TextFieldProps = {
  label?: string;
  warn?: string;
  iconName?: string;
  iconPosition?: 'start' | 'end';
  handleChangeCallback?: (value: string) => void;
  color?: string;
  mask?: 'cpf' | 'cnpj' | 'cep' | 'money';
  initialValue?: string
  name?: string;
  className?: string;
  placeholder?: string;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({
  label,
  warn,
  iconName,
  iconPosition = 'start',
  color = '#596778',
  handleChangeCallback,
  mask,
  initialValue,
  name,
  className,
  placeholder,
}, ref) => {

  const [inputValue, setInputValue] = useState(initialValue);

  const masksEnum = {
    cpf: formatCpf,
    cnpj: formatCnpj,
    cep: formatCep,
    money: formatMoney,
    number: formatMoneyWithoutCurrency,
  }

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = mask ? masksEnum[mask](e.target.value) : e.target.value;

    handleChangeCallback && handleChangeCallback(inputValue);
    setInputValue(inputValue);
  };

  return <Container color={color}>
    {label && <label>{label}</label>}
    <InputContainer className={className} iconPosition={iconPosition} color={color}>
      {iconPosition === 'start' && iconName && <Icon name={iconName} />}
      <input type="text" placeholder={placeholder} name={name} value={inputValue} ref={ref} onChange={handleChangeValue} />
      {iconPosition === 'end' && iconName && <Icon name={iconName} />}
    </InputContainer>
    
    {warn && <p>{warn}</p>}
  </Container>
});
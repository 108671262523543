import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: stretch;

  .inputsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    select {
      border: none;
      border-bottom: 1px solid #596778;
      background-color: transparent;
      color: #596778;
      margin-right: 8px;
      flex-grow: 0;
    }
  }

  .funnelContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    svg {
      position: relative;
    }

    svg:not(:first-of-type) {
      margin-top: -10px;
    }

    svg:nth-child(0) {
      z-index: 6;
    }
    svg:nth-child(1) {
      z-index: 5;
    }
    svg:nth-child(2) {
      z-index: 4;
    }
    svg:nth-child(3) {
      z-index: 3;
    }
    svg:nth-child(4) {
      z-index: 2;
    }
  }
`;
 
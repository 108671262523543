import styled, { css } from 'styled-components';

type ContainerProps = {
  color: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  label, p {
    font-size: 14px;
    color: ${props => props.color};
    margin-left: 8px;
  }

  p {
    font-size: 12px;
  }
  
`;

type InputContainerProps = {
  iconPosition: 'start' | 'end';
  color: string;
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;

  box-sizing: border-box;
  transition: all 0.2s;
  padding: 4px 8px;
  margin: 4px 0;
  border-radius: 5px;
  border: ${props => `1px solid ${props.color}`};
  background-color: #fff;
  font-size: 14px;
  color: #596778;

  &:hover {
    border-width: 2px;
    margin: 3px 0;
  }

  input {
    flex: 1;
  }
  
  svg {

    fill: ${props => props.color};
    
    ${props => props.iconPosition === 'start' ? 
    css`
      margin-right: 8px;
    `
    : 
    css`
      margin-left: 8px;
    `
    }
  }
`;

import styled, { css } from 'styled-components';

type ContainerProps = {
  hasError: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: bold;
  background-color: #F2F4F4;
  color: #596778;
  border: 1px dashed #8C8B8B;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 60px;
  }

  svg {
    fill: #ADC86A;
  }

  ${props => !props.hasError && css`&:hover {
    color: #45924F;
    border-color: #45924F;

    svg {
      width: 25px;
      height: 25px;
      fill: #45924F;
    }
  }`}

  ${props => props.hasError && css`
      color: #FF3333;
      border-color: #FF3333;

      svg {
        width: 25px;
        height: 25px;
        fill: #FF3333;
      }
  `}
`;
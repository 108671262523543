import styled from 'styled-components';

type ContainerProps = {
  type: 'simple' | 'complex';
  hasValue: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  
  border: ${props => props.type === 'complex' && '1px solid #596778'};
  border-bottom: ${props => props.type === 'simple' && '1px solid #596778'};
  background-color: ${props => props.type === 'simple' ? 'transparent' : '#F2F4F4'};
  border-radius: ${props => props.type === 'complex' && '5px'};

  &:hover { 
    border: ${props => props.type === 'complex' && '1px solid #596778'};
    border-bottom: ${props => props.type === 'simple' && '2px solid #596778'};
  }

  border-bottom: ${props => props.type === 'simple' && props.hasValue && '2px solid #596778'};

  svg {
    fill: #b0ca70;
  }

  input {
    flex: 1;
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: #596778;
  }
`;

export const ItemsContainer = styled.div`
  margin: -16px;

  p {
    padding: 0 16px;
    cursor: pointer;
  }

  & p:hover {
    background-color: #ccc;
  }
`;
import { format } from "date-fns";

export const formatCpf = (value: string | number) => {
  const rawValue = value.toString().replace(/\D/g, '')

  if (rawValue.length < 11) {
    return rawValue.toString()
  }

  return rawValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatCnpj = (value: string | number) => {
  const rawValue = value.toString().replace(/\D/g, '')

  if (rawValue.length < 14) {
    return rawValue.toString()
  }

  return rawValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export const formatMoney = (value: string | number) => {
  const rawValue = value.toString().replace(/\D/gi, "").padStart(4, '0').replace(/(\d{2}$)/gi, ".$1");

  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(+rawValue);
}

export const formatMoneyWithoutCurrency = (value: string | number) => {
  const rawValue = value.toString().replace(/\D/gi, "").padStart(4, '0').replace(/(\d{2}$)/gi, ".$1");
  return +rawValue
}

export const formatDate = (value: string) => format(new Date(value), "dd/MM/yyyy");

export const formatCep = (value: string) => {
  const rawValue = value.replace(/\D/gi, '');

  if (rawValue.length === 8) {
    return rawValue.replace(/(\d{3}$)/gi, "-$1");
  }

  return rawValue.length < 8 ? rawValue : rawValue.slice(0, 7);
}
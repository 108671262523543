import styled, { css } from 'styled-components';

type ContainerProps = {
  hasElevation: boolean;
  headerColor: string;
  textColor: string;
  bold: boolean;
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 5px;
  box-shadow: ${props => props.hasElevation && '0px 1px 3px #00000033'};
  transition: all 0.3s;
  user-select: none;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    background-color: ${props => props.headerColor};

    .startBlock {
      display: flex;
      align-items: center;

      p {
        color: ${props => props.textColor};
        font-weight: ${props => props.bold ? "bold" : "normal"};
        font-size: 15px;
      }

      svg {
        fill: ${props => props.textColor};
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }

    .endBlock {
      cursor: pointer;
      svg {
        fill: ${props => props.textColor};
      }
    }
  }

  section {
    background-color: #fff;
    padding: ${props => props.isOpen ? '16px' : '0 16px'};
    transform-origin: top;
    transform: scaleY(1);
    transition: all 0.2s;
    border-radius: 5px;

    ${props => props.isOpen === false && css`
      transform: scaleY(0);
    `}
  }


`;
